.power-plants-page{
    .section-title{
        text-transform: none;
        font-size: 25px;
        font-weight: 500;
    }

    p{
        line-height: 1.6 !important;
        text-align: left;
        font-size: 15px;
    }

    .text-page-share-back{
        bottom: 15px;

        a{
            font-size: 13px;
            text-decoration: none;
        }
        .property-back{
            border-right: 1px solid #c7c7c7;
            padding-right: 15px;
        }
    }

    .share-back-wrap{
        @media (max-width: 720px){
            margin-top: 20px;
        }
    }
}

.section {
    &.blog-post-section {
        .blog-post-date {
            font-family: font(sub);
            font-weight: 600;
            font-size: 0.9rem;
            text-align: left;
            display: block;
            margin-top: 1rem;
        }

        .blog-post-img-container {
            text-align: center;
        }
    }

    .blog-post-video-container {
        #video-holder {
            .img-overlay {
                position: relative;
                display: inline !important;
            }

            iframe {
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }
}


.documents-list {
    font-size: 14px;
    border: 1px solid #ccc;
    padding: 20px;
    background-color: white;

    li {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }

        i {
            font-size: 18px;
            color: black;
            margin-right: 15px;
        }

        a {
            font-size: 14px;
            color: color('primary');
            text-decoration: underline;
            font-weight: 600;
        }
    }
}