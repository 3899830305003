@import './_config/scss/config.scss';
@import './global.scss';
/* Layout imports */
@import './layout/layout.scss';
/* Layout imports end */
/* Modules import */
@import './modules/modules.scss';
/* Modules impor end */
/* Views Import */
@import './page_categories/page_categories.scss';
/* Views Import End */
/* COMPONENTS */
@import './components/componenets.scss';
/* COMPONENETS END */
/* Utilities import */
@import './utilities/utilities.scss';
/* Utilities import end */


@import './FeatureWidget/FeatureWidget.scss';

.instagram-feed {
    .instagram-post {
        .caption {

        }
    }
}