.references-section {
    padding-top: 80px;
    padding-bottom: 0;

    .references-title {
        font-style: normal;
        text-align: left ;
        text-transform: none;
        font-size: 25px;
        font-weight: 500;
    }

    .share-back-wrap {
        margin-top: 15px;
    }

    .text-page-share-back {
        bottom: 15px;

        a {
            font-size: 13px;
            text-decoration: none;
        }

        .property-back {
            border-right: 1px solid #c7c7c7;
            padding-right: 15px;
        }
    }

    .border-bottom {
        border-bottom: 1px solid #e5e5e5;
    }

    hr {
        &.references-separator {
            margin-top: 15px;
            margin-bottom: 15px;
            width: 60px;
        }
    }

    .section-subtitle {
        &.reference-title {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            text-align: left;
            margin-bottom: 20px;
        }
    }

    hr {
        &.reference-separator {
            width: 60px;
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }

    .section-text {
        &.reference-text {
            clear: both;
            text-align: left;
            margin-top: 0px !important;

            p {
                margin-bottom: 0;
            }

            a {
                color: #3f7ddb;
                font-size: 14px;
                font-weight: 700;
                font-family: "Open Sans", sans-serif;
                display: block;

                &:hover {
                    text-decoration: none;
                    color: color('primary');
                    border-color: color('primary');
                }

                i {
                    margin-left: 5px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    .single-reference {
        padding: 35px 0;

        &:nth-of-type(even) {
            background: #faf7f5;
        }
    }
}

.SumoSelect.sumo_references_categories_filter {
    width: 200px;
    float: right;
    position: relative;
    top: 30px;
    margin: 0;
    z-index: 9;

    @media (max-width: 768px) {
        float: none;
        margin-bottom: 30px;
    }

    .CaptionCont > span {
        line-height: 32px;
    }

    .CaptionCont > label > i {
        line-height: 34px;
    }
}



