.section {
    &.home-carousel {
        padding-top: 0;
        padding-bottom: 0;
        color: color('text', 'light');
        margin-bottom: 60px;

        .home-carousel-item {
            padding: 0;
        }

        .container-fluid {
            .navi {
                &.navi-prev {
                    left: 1px;
                    color: color('white');
                }

                &.navi-next {
                    right: 1px;
                    color: color('white');
                }
            }

        }

        .container {
            .navi {
                &.navi-prev {
                    left: -30px;
                    color: color('white');
                }

                &.navi-next {
                    right: -30px;
                    color: color('white');
                }
            }

        }

        .home-carousel-wrapper {

            &.carousel-center {
                .slick-list {
                    margin: 0 -22px;
                }

                .slick-track {
                    margin-left: auto !important;
                    margin-right: auto !important;
                }

                .home-carousel-item {
                    margin-right: 22px;

                    &:first-of-type {
                        margin-left: 22px;
                    }
                }
            }

            .slick-list {
                padding-bottom: 35px;
            }

            /* SLICK NAVIGATION ARROWS */
            .navi {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                z-index: 1;
                cursor: pointer;
                font-size: 30px;
                background: color('primary');
                line-height: 30px;
                width: 30px;
                height: 30px;
                border-radius: $border_radius;
                -webkit-border-radius: $border_radius;
                -moz-border-radius: $border_radius;
                -ms-border-radius: $border_radius;
                -o-border-radius: $border_radius;


                &.slick-disabled {
                    @include opacity(0.2);
                    pointer-events: none;
                    cursor: default;
                }

                i {
                    font-size: 2.142857142857143rem;
                    font-weight: 600;
                }
            }

            .navi {
                color: #ffffff;
            }

            .slick-dots {
                position: absolute;
                bottom: -70px;
            }
        }


        .home-carousel-item {
            margin: 0 22px;

            @include box-shadow(0px 12px 20px 0px rgba(0, 0, 0, 0.25));

            .carousel-item-info {
                padding: 15px 30px;
                margin-top: 10px;
            }

            .carousel-item-title {
                font-family: font(main);
                font-weight: 400;
                font-size: 16px;
                color: #63666c;
                text-transform: uppercase;
            }

            .carousel-link {
                font-size: 24px;
                font-weight: 400;
                text-transform: none;

                &:hover {
                    text-decoration: none;
                }
            }

            .carousel-item-desc {
                p {
                    font-size: 14px;
                    font-family: font(sub);
                    color: #939599;
                    font-weight: 400;
                    line-height: 24px;
                    max-height: 185px;
                    overflow: hidden;
                }

                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 14px;
                font-family: font(sub);
                color: color('text', 'light');
                font-weight: 400;

            }

            .carousel-item-img {
                width: 100%;

                @media (max-width: 576px) {
                    margin: 0 auto;
                }
            }

            .carousel-item-link {
                color: color('primary');
                font-size: 14px;
                font-weight: 700;
                font-family: font(sub);
                display: block;

                i {
                    font-size: 16px;
                    color: color('primary');
                    margin-right: 5px;
                }

                &:hover {
                    text-decoration: none;

                    i {
                        -webkit-animation: leftToRight 1s ease-out infinite;
                        animation: leftToRight 1s ease-out infinite;
                    }
                }
            }
        }

    }
}

.news-home-section {
    margin: 60px 0 60px;

    h2 {
        color: #4b4f55;
    }

    .news-home-wrap {
        @media (min-width: 1461px) {
            width: 1461px;
        }

    }

    .carousel-item-info {
        padding: 10px 0 0 !important;
    }

    .news-home-item {
        box-shadow: unset !important;
    }

    .news-home-title {
        margin: 10px 0 0;
    }
}

.home-news-next,
.home-news-prev {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    z-index: 1;
    cursor: pointer;
    font-size: 30px;
    background: #4b4f55;
    color: white;
    line-height: 30px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.slick-disabled {
        opacity: 30%;
    }

    @media (max-width: 992px) {
        width: 20px;
    }
}


