/* HEADER STYLES */
$margin_y: 20px;

.homepage .header-container {
    width: 100%;
    max-width: 100vw;
    color: color('nav', 'text', 'main');
    height: auto;

    /*.logo-color{
        display: none;
    }*/

    &.scrolled {
        background: color('nav', 'background', 'main');

        .nav-link {
            color: color('primary') !important;
        }

        /*.logo-white{
            display: none;
        }*/
        /*.logo-color{
            display: block;
        }*/

        .menu-icon {
            span {
                background-color: color('primary');
            }
        }

    }

    &.fixed-top {
        backface-visibility: hidden;
    }

    &.scrolled {
        .img-fluid {
            transition: all ease 1s;
        }
    }

    .header-nav-top {
        background: transparent;
        max-height: 40px;
        font-size: 12px;
        margin-top: 5px;

        .company-info-header {
            @media(max-width: $media_sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .header-nav-main {
        margin-left: 6em;
        margin-right: 5em;

        @include transition(height 0.3s linear);

        @media (max-width: 992px) {
            height: auto;
            max-height: 500px;
        }

        @media(max-width: 700px) {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @media(max-width: $media_xxl) {
            margin-left: 0;
            margin-right: 0;
        }

        /* Header logo */
        .company-brand {
            text-align: left;
            padding-left: 35px;
        }

        .header-nav {
            position: relative;
            bottom: 0;
            font-size: 14px;
            line-height: 80px;
            font-family: font(sub);
            font-weight: 400;
            margin-bottom: 0;

            .nav-item {

                &:hover,
                &.active {
                    border-bottom: 1px solid color('white');
                }

                position: relative;

                &:hover {
                    .dropdown-menu {
                        display: block;
                        position: absolute;
                        top: 31px;
                        left: -1px;
                    }
                }

                .dropdown-menu {
                    z-index: 1030;
                    line-height: 1.5;
                    background: color('nav', 'background', 'accent');
                    color: color('nav', 'text', 'main');
                    @include border-radius(0);

                    li {
                        &:not(:last-of-type) {
                            border-bottom: 1px solid color('primary');
                        }

                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    .dropdown-item {

                        &.active,
                        &:hover {
                            background: color('primary');
                            color: color('white');
                        }

                        font-size: 14px;
                        font-family: font(main);
                        text-transform: uppercase;


                    }
                }

                .nav-link {
                    padding: 0 10px;
                    line-height: 35px;
                    color: white;
                }
            }
        }
    }

    /* Header social icons */
    .social-container {
        position: relative !important;
        padding: 0;
        margin-left: 20px;

        .social-popup,
        .social-popup-trigger {
            display: none;
        }

        .social-link {
            color: color('white');
            text-align: center;
            padding-left: 0;
            border: none;
            line-height: 40px;

            &.icon-only {
                font-size: 15px;
                padding-right: 15px;
                line-height: 40px;
                display: inline-block;

                i {
                    font-size: 15px;
                }

                i.fa-facebook {
                    font-size: 15px;
                }

                i.fa-google-plus {
                    font-size: 15px;
                }
            }

            &:not(:first-of-type) {
                padding-left: 15px;
            }

            &:not(:last-of-type) {
                /* border-right: 1px solid color('border', 'light') !important;*/
            }

            &:first-of-type {
                // padding-left: 25px;
                padding-right: 15px;
            }

            i {
                vertical-align: middle;
                color: color('white');
                line-height: 30px;
            }

        }
    }

    /* HEADER CONTACT */
    .contact-info {
        text-align: right;
        display: inline-block;

        a {
            line-height: 40px;
            font-family: font(sub);
            display: inline-block;
            color: color('white');
            padding: 0 10px;
            font-size: 13px;
            padding-left: 15px;

            &:hover {
                text-decoration: none;
            }
        }

        i {
            color: color('white');
            line-height: 30px;
            padding-right: 5px;

            &.fa-phone {
                font-size: 15px;
            }

            &.fa-skype {
                font-size: 1.4rem;
            }

            &.fa-envelope {
                font-size: 14px;
            }
        }

        .nav-contact-text {
            display: none;

            @media (min-width: 1600px) {

                display: inline;
            }
        }

        &.text-muted {
            display: inline;
            margin-left: 1px;


        }

        label {
            display: inline;
            margin: 0;
        }
    }

    .lang-wrap {
        margin-right: 5px;
        position: relative;
        top: 5px;

        .dropdown-menu {
            top: 20px !important;
        }

        a {
            line-height: 30px;
            text-decoration: none;
            text-transform: capitalize;
            font-size: 13px;
            font-family: font(sub);
            font-weight: 500;
            color: color('nav', 'text', 'main');

            &.dropdown-toggle::after {
                border: 0 !important;
                content: "\f078" !important;

                vertical-align: 0 !important;
                margin-left: 5px !important;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 10px;

            }

            .lang-label {
                @media(max-width: $media_md) {
                    display: none;
                }
            }

            img {
                width: 21px;
                margin-right: 10px;
            }

            @include transition(all .3 linear);

            &.active {
                color: color('primary');
            }

            &:hover {
                opacity: 0.7;
            }

            // &:not(:first-of-type) {
            //     padding-left: 10px;

            // }

            // &:not(:last-of-type) {
            //     border-right: 1px solid color(border-light);
            //     padding-right: 10px;
            //     padding-left: 25px;
            // }
        }
    }


    /* BUTTON HAMBURGER */
    .btn-menu {
        margin-left: auto;
        display: inline-block;
        padding: $margin_y+10;
        color: color('text', 'dark');

        &:hover {
            text-decoration: none;
        }

        .menu-text {
            text-transform: uppercase;
            font-size: 1rem;
            font-family: font(sub);
            font-weight: 600;
            line-height: 32px;
            position: relative;
            top: -5px;
            left: 10px;
        }

        span {
            display: block;
            background-color: color('white');
            width: 20px;
            height: 2px;
            margin-bottom: 4px;
        }
    }

    /* MEDIA QUERIES */
    @media (max-width: 1700px) {
        .contact-info {
            span {
                line-height: 30px;
                padding: 0 8px;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    @media (max-width: 1600px) {
        .header-container {
            .social-container {
                .social-link {
                    width: 40px;
                    line-height: 30px;
                }
            }
        }

        .contact-info {
            span {
                line-height: 30px;
                padding: 0 8px;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    @media (max-width: 1300px) {
        .contact-info {

            //text-align: left;
            span {
                label {
                    display: none;
                }
            }
        }
    }

    @media(max-width: 1040px) {
        .contact-info {
            span {
                padding: 0 4px;
                border: 0 !important;
                font-size: 1.1rem;
            }
        }
    }

    @media (max-width: $media_xl) {
        .contact-info {
            .text-muted {
                display: none;
            }
        }
    }

    @media (max-width: $media_lg) {


        .btn-menu {
            margin-top: 5px;

            .menu-text {
                display: none !important;
            }
        }

        .mobile-lang {
            font-size: 1rem;
            height: 38px;
        }

        .mobile-book {
            position: relative;
            top: 45px;
            font-size: 1.2rem;
            background-color: color('primary');

            i {
                color: color('white');
            }
        }
    }
}

// END of HOMEPAGE


//////////////////////// OTHER PAGES /////////////////////////////////
.header-container {
    width: 100%;
    max-width: 100vw;
    color: #575b60;
    height: auto;
    /* background: color('nav', 'background', 'main');*/


    .header-nav-top {
        background: transparent;
        max-height: 40px;
        font-size: 12px;
    }

    .header-nav-main {

        /* Header logo */
        .company-brand {
            text-align: left;
            align-items: center;
            display: flex;
            padding-left: 35px;

            /*img{
                height: 40px;
            }*/
        }


        .header-nav {
            position: relative;
            bottom: 0;
            font-size: 14px;
            line-height: 80px;
            font-family: font(sub);
            font-weight: 400;
            margin-bottom: 0;

            .nav-item {

                &:hover,
                &.active {
                    border-bottom: 1px solid #575b60;
                }

                position: relative;

                &:hover {
                    .dropdown-menu {
                        display: block;
                        position: absolute;
                        top: 31px;
                        left: -1px;
                    }
                }

                .dropdown-menu {
                    z-index: 1030;
                    line-height: 1.5;
                    background: color('nav', 'background', 'accent');
                    color: color('nav', 'text', 'main');
                    @include border-radius(0);

                    li {
                        &:not(:last-of-type) {
                            border-bottom: 1px solid color('primary');
                        }

                        margin-left: 10px;
                        margin-right: 10px;
                    }

                    .dropdown-item {

                        &.active,
                        &:hover {
                            background: color('primary');
                            color: color('white');
                        }

                        font-size: 14px;
                        font-family: font(main);
                        text-transform: uppercase;


                    }
                }

                .nav-link {
                    padding: 0 10px;
                    line-height: 35px;
                    color: #575b60;
                }
            }
        }
    }

    /* Header social icons */
    .social-container {
        position: relative !important;
        padding: 0;
        margin-left: 20px;

        .social-popup,
        .social-popup-trigger {
            display: none;
        }

        .social-link {
            color: color('white');
            text-align: center;
            padding-left: 0;
            border: none;
            line-height: 40px;

            &.icon-only {
                font-size: 15px;
                padding-right: 15px;
                line-height: 40px;
                display: inline-block;

                i {
                    font-size: 15px;
                }

                i.fa-facebook {
                    font-size: 15px;
                }

                i.fa-google-plus {
                    font-size: 15px;
                }
            }

            &:not(:first-of-type) {
                padding-left: 15px;
            }

            &:not(:last-of-type) {
                /* border-right: 1px solid color('border', 'light') !important;*/
            }

            &:first-of-type {
                // padding-left: 25px;
                padding-right: 15px;
            }

            i {
                vertical-align: middle;
                color: #575b60;
                line-height: 30px;
            }

        }
    }

    /* HEADER CONTACT */
    .contact-info {
        text-align: right;
        display: inline-block;

        a {
            line-height: 40px;
            font-family: font(sub);
            display: inline-block;
            color: #575b60;
            padding: 0 10px;
            font-size: 15px;
            padding-left: 15px;

            &:hover {
                text-decoration: none;
            }
        }

        i {
            color: #575b60;
            line-height: 30px;
            padding-right: 5px;

            &.fa-phone {
                font-size: 14px;
            }

            &.fa-skype {
                font-size: 1.4rem;
            }

            &.fa-envelope {
                font-size: 14px;
            }
        }

        .nav-contact-text {
            display: none;

            @media (min-width: 1600px) {

                display: inline;
            }
        }

        &.text-muted {
            display: inline;
            margin-left: 1px;


        }

        label {
            display: inline;
            margin: 0;
        }
    }

    .lang-wrap {
        margin-right: 5px;
        position: relative;
        top: 5px;

        .dropdown-menu {
            top: 20px !important;
        }

        a {
            line-height: 30px;
            text-decoration: none;
            text-transform: capitalize;
            font-size: 13px;
            font-family: font(sub);
            font-weight: 500;
            color: color('nav', 'text', 'main');

            &.dropdown-toggle::after {
                border: 0 !important;
                content: "\f078" !important;

                vertical-align: 0 !important;
                margin-left: 5px !important;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 10px;
            }

            .lang-label {
                @media(max-width: $media_md) {
                    display: none;
                }
            }

            img {
                width: 21px;
                margin-right: 10px;
            }

            @include transition(all .3 linear);

            &.active {
                color: color('primary');
            }

            &:hover {
                opacity: 0.7;
            }

            // &:not(:first-of-type) {
            //     padding-left: 10px;

            // }

            // &:not(:last-of-type) {
            //     border-right: 1px solid color(border-light);
            //     padding-right: 10px;
            //     padding-left: 25px;
            // }
        }
    }

    /* BUTTON HAMBURGER */
    .btn-menu {
        margin-left: auto;
        display: inline-block;
        padding: $margin_y+10;
        color: color('text', 'dark');

        &:hover {
            text-decoration: none;
        }

        .menu-text {
            text-transform: uppercase;
            font-size: 1rem;
            font-family: font(sub);
            font-weight: 600;
            line-height: 32px;
            position: relative;
            top: -5px;
            left: 10px;
        }

        span {
            display: block;
            background-color: color('text', 'dark');
            width: 20px;
            height: 2px;
            margin-bottom: 4px;
        }
    }

    /* MEDIA QUERIES */
    @media (max-width: 1700px) {
        .contact-info {
            span {
                line-height: 30px;
                padding: 0 8px;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    @media (max-width: 1600px) {
        .header-container {
            .social-container {
                .social-link {
                    width: 40px;
                    line-height: 30px;
                }
            }
        }

        .contact-info {
            span {
                line-height: 30px;
                padding: 0 8px;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    @media (max-width: 1300px) {
        .contact-info {

            //text-align: left;
            span {
                label {
                    display: none;
                }
            }
        }
    }

    @media(max-width: 1040px) {
        .contact-info {
            span {
                padding: 0 4px;
                border: 0 !important;
                font-size: 1.1rem;
            }
        }
    }

    @media (max-width: $media_xl) {
        .contact-info {
            .text-muted {
                display: none;
            }
        }
    }

    @media (max-width: $media_lg) {


        .btn-menu {
            margin-top: 5px;

            .menu-text {
                display: none !important;
            }
        }

        .mobile-lang {
            font-size: 1rem;
            height: 38px;
        }

        .mobile-book {
            position: relative;
            top: 45px;
            font-size: 1.2rem;
            background-color: color('primary');

            i {
                color: #575b60;
            }
        }
    }
}

/// END OF OTHER PAGES ///
body:not(#homepage) {
    .header-container {
        background: color('nav', 'background', 'main');

        .header-nav-top {
            border-bottom: 1px solid #dddddd;
        }

        /*.logo-white{
            display: none;
        }*/
    }
}
