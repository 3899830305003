.banner-fluid-section {
    height: 453px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: color('white');
        font-size: 45px;
        padding: 0 600px;
        text-align: center;
        height: 453px;
        display: flex;
        align-items: center;

    @media (max-width: 992px) {
        font-size: 30px;
        padding: 0 20px;
    }
    }
}


