.contact-block-section {
    background-color: #f2f2f2;

    .contact-title{
        text-transform: none;
        font-size: 40px;
    }

    .btn-wrapper {
        text-align: center;
    }

    .contact-btn {
        background: #4b4f55;
        border-radius: unset;
        text-decoration: none;
        text-transform: uppercase;
        font-family: font(sub);
        font-size: 14px;
        font-weight: 400;
        color: color('white');
        padding: 2px 30px;
        border-color: #4b4f55 !important;
        @include transition(all .3s linear);


        &:hover {
            color: #4b4f55;
            background: transparent;
        }
    }
}